import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTING_TREE } from "../../constants/siteUrls";
import {
	useJwtLoginMutation,
	useJwtLogoutMutation,
	useLoginWithBranchIdMutation,
	useMeByBranchQuery,
	useMeQuery,
	useRequestVerifyMutation,
} from "../../store/api/auth.api";
import { setMe, setToken } from "../../store/slices/auth.slice";
import { UserType } from "../auth.utils";

const initialValue = {
	email: "",
	password: "",
	password2: "",
	showPassword: false,
};
const initialValueBranch = {
	branch_id: "",
	password: "",
};

const useAuth = (props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [cookies, setCookie, removeCookie] = useCookies(["me", "access_token", "token_type", "is_keep_sign_in", "branchId"]);

	// Data from store
	const user = useSelector((state) => state.auth.user);
	const me = useSelector((state) => state.auth.me);
	const access_token = useSelector((state) => state.auth.access_token);
	const token_type = useSelector((state) => state.auth.token_type);

	var today = new Date();
	var cookiesExpiresDay = new Date(new Date().setDate(today.getDate() + 30));

	// Auth form data
	const [authForm, setAuthForm] = useState(initialValue);
	const [authBranchForm, setAuthBranchForm] = useState(initialValueBranch);
	// If user is not verified
	const [notVerified, setNotVerified] = useState(false);
	// If user is not authorized
	const [unauthorized, setUnauthorized] = useState(false);
	// If credentials are bad
	const [badCredentials, setBadCredentials] = useState(false);
	const [skip, setSkip] = useState(true);
	const [skipBranch, setSkipBranch] = useState(true);
	// Keep Sign In
	const [keepSignIn, setKeepSignIn] = useState(false);

	// Login api
	const [jwtLogin, { data: loginData, error: loginError, isLoading: loginIsLoading }] = useJwtLoginMutation();

	// Logout api
	const [jwtLogout, { data: logoutData, error: logoutError, isLoading: logoutIsLoading }] = useJwtLogoutMutation();

	const [loginWithBranchId, { data: loginBranchData, error: loginBranchError, isLoading: loginBranchIsLoading }] =
		useLoginWithBranchIdMutation();

	// Account verification api
	const [requestVerification, { data: requestVerificationData, error: requestVerificationError }] =
		useRequestVerifyMutation();

	// Self data api
	const { data: dataMe, error: errorMe, refetch: refetchMe } = useMeQuery({}, { skip: skip });

	const {
		data: dataBranchMe,
		error: errorBranchMe,
		refetch: refetchBranchMe,
	} = useMeByBranchQuery({}, { skip: skipBranch });

	const handleCheckKeepSignIn = (keepSignIn) => {
		setCookie("is_keep_sign_in", keepSignIn, {
			expires: cookiesExpiresDay,
			path: "/",
		});
		setKeepSignIn(keepSignIn);
	};

	useEffect(() => {
		if (cookies.is_keep_sign_in === "true") {
			setKeepSignIn(true);
		} else {
			setKeepSignIn(false);
		}
	}, [cookies.is_keep_sign_in]);

	useEffect(() => {
		if (cookies.me) {
			dispatch(setMe(cookies.me));
		}

		if (cookies.access_token && cookies.token_type) {
			dispatch(
				setToken({
					access_token: cookies.access_token,
					token_type: cookies.token_type,
				}),
			);
		} else if (cookies.me && cookies.is_keep_sign_in === "true") {
			setCookie("access_token", sessionStorage.getItem("access_token"), {
				expires: cookiesExpiresDay,
				path: "/",
			});
			setCookie("token_type", sessionStorage.getItem("token_type"), {
				expires: cookiesExpiresDay,
				path: "/",
			});
		} else if (cookies.me && cookies.is_keep_sign_in === "false") {
			setCookie("access_token", sessionStorage.getItem("access_token"), {
				path: "/",
			});
			setCookie("token_type", sessionStorage.getItem("token_type"), {
				path: "/",
			});
		} else {
			// dispatch(setMe(null));
			// dispatch(setToken({ access_token: null, token_type: null }));
			// setUnauthorized(false);
			// removeCookie("access_token", { expires: cookiesExpiresDay, path: "/" });
			// removeCookie("token_type", { expires: cookiesExpiresDay, path: "/" });
			// removeCookie("me", { expires: cookiesExpiresDay, path: "/" });
			// removeCookie("is_keep_sign_in", { path: "/" });
			// if (location.pathname.includes("/super") || location.pathname.includes("/company")) {
			// 	navigate(ROUTING_TREE.HOME.HOME);
			// } else if (location.pathname.includes("/in-store")) {
			// 	navigate(`/${ROUTING_TREE.IN_STORE_AUTH.AUTH}/${ROUTING_TREE.IN_STORE_AUTH.BRANCH_ADMIN_LOGIN}`);
			// } else {
			// 	navigate(ROUTING_TREE.HOME.HOME);
			// }
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cookies]);

	// Handle data returning after login
	useEffect(() => {
		if (loginData) {
			if (loginData?.errors?.includes("LOGIN_USER_NOT_VERIFIED")) {
				setNotVerified(true);
				return false;
			}
			if (loginData?.errors?.includes("LOGIN_BAD_CREDENTIALS")) {
				setBadCredentials(true);
				return false;
			}

			if (loginData?.access_token) {
				setNotVerified(false);
				setSkip(false);
				refetchMe();
				setAuthForm(initialValue);
				dispatch(setToken(loginData));

				localStorage.setItem("access_token", loginData.access_token);
				localStorage.setItem("token_type", loginData.token_type);

				sessionStorage.setItem("access_token", loginData.access_token);
				sessionStorage.setItem("token_type", loginData.token_type);

				setCookie("access_token", loginData.access_token, {
					path: "/",
				});
				setCookie("token_type", loginData.token_type, {
					path: "/",
				});

				// if (cookies.is_keep_sign_in === "true") {
				// 	setCookie("access_token", loginData.access_token, {
				// 		// expires: cookiesExpiresDay,
				// 		path: "/",
				// 	});
				// 	setCookie("token_type", loginData.token_type, {
				// 		// expires: cookiesExpiresDay,
				// 		path: "/",
				// 	});
				// } else {
				// 	sessionStorage.setItem("access_token", loginData.access_token);
				// 	sessionStorage.setItem("token_type", loginData.token_type);
				// }
			}
		}

		if (loginError) {
			console.log(loginError);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loginData, loginError]);
	useEffect(() => {
		if (loginBranchData) {
			if (loginBranchData?.errors?.includes("LOGIN_USER_NOT_VERIFIED")) {
				setNotVerified(true);
				return false;
			}
			if (loginBranchData?.errors?.includes("LOGIN_BAD_CREDENTIALS")) {
				setBadCredentials(true);
				return false;
			}

			if (loginBranchData?.access_token) {
				setNotVerified(false);
				setSkipBranch(false);
				refetchBranchMe();
				setAuthBranchForm(initialValueBranch);
				dispatch(setToken(loginBranchData));

				localStorage.setItem("access_token", loginBranchData.access_token);
				localStorage.setItem("token_type", loginBranchData.token_type);

				sessionStorage.setItem("access_token", loginBranchData.access_token);
				sessionStorage.setItem("token_type", loginBranchData.token_type);

				setCookie("access_token", loginBranchData.access_token, {
					path: "/",
				});
				setCookie("token_type", loginBranchData.token_type, {
					path: "/",
				});

				// if (cookies.is_keep_sign_in === "true") {
				// 	setCookie("access_token", loginBranchData.access_token, {
				// 		expires: cookiesExpiresDay,
				// 		path: "/",
				// 	});
				// 	setCookie("token_type", loginBranchData.token_type, {
				// 		expires: cookiesExpiresDay,
				// 		path: "/",
				// 	});
				// } else {
				// 	sessionStorage.setItem("access_token", loginBranchData.access_token);
				// 	sessionStorage.setItem("token_type", loginBranchData.token_type);
				// }
			}
		}

		if (loginBranchError) {
			console.log(loginBranchError);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loginBranchData, loginBranchError]);

	const removeCookiesLogout = () => {
		removeCookie("access_token", { path: "/" });
		removeCookie("token_type", { path: "/" });
		removeCookie("me", { path: "/" });
		removeCookie("is_keep_sign_in", { path: "/" });
		removeCookie("branchId", { path: "/" });
		localStorage.clear();
		sessionStorage.clear();
		if (location.pathname.includes("/super") || location.pathname.includes("/company")) {
			navigate(ROUTING_TREE.HOME.HOME);
		} else if (location.pathname.includes("/in-store")) {
			navigate(`/${ROUTING_TREE.IN_STORE_AUTH.AUTH}/${ROUTING_TREE.IN_STORE_AUTH.BRANCH_ADMIN_LOGIN}`);
		} else {
			navigate(ROUTING_TREE.HOME.HOME);
		}
		// setUnauthorized(true);
	};

	const handleLogout = () => {
		dispatch(setMe(null));
		dispatch(setToken({ access_token: null, token_type: null }));
		setUnauthorized(false);
		removeCookie("access_token", { path: "/" });
		removeCookie("token_type", {  path: "/" });
		removeCookie("me", { path: "/" });
		removeCookie("is_keep_sign_in", { path: "/" });
		removeCookie("branchId", { path: "/" });
		localStorage.clear();
		sessionStorage.clear();
		// removeCookie("is_keep_sign_in", { path: "/" });

		if (location.pathname.includes("/super") || location.pathname.includes("/company")) {
			navigate(ROUTING_TREE.HOME.HOME);
		} else if (location.pathname.includes("/in-store")) {
			navigate(`/${ROUTING_TREE.IN_STORE_AUTH.AUTH}/${ROUTING_TREE.IN_STORE_AUTH.BRANCH_ADMIN_LOGIN}`);
		} else {
			navigate(ROUTING_TREE.HOME.HOME);
		}
		window.location.reload();
	};

	// Handle data returning after logout
	useEffect(() => {
		if (logoutData) {
			if (logoutData?.errors?.includes("Unauthorized")) {
				removeCookiesLogout();
				return false;
			}
		} else if (logoutData === null) {
			handleLogout();
		}

		if (logoutError) {
			console.log(logoutError);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [logoutData, logoutError]);

	const setRouting = (data) => {
		if (data?.errors?.includes("Unauthorized")) {
			setUnauthorized(true);
			return false;
		}

		if (data?.email) {
			dispatch(setMe(data));
			setCookie("me", data, {
				expires: cookiesExpiresDay,
				path: "/",
			});
			setUnauthorized(false);

			if (data?.user_type === UserType.SUPER_ADMIN)
				navigate(`/${ROUTING_TREE.SUPER_ADMIN.SUPER}/${ROUTING_TREE.SUPER_ADMIN.DASHBOARD}`);
			else if (data?.user_type === UserType.COMPANY_ADMIN)
				navigate(`/${ROUTING_TREE.COMPANY_ADMIN.COMPANY}/${ROUTING_TREE.COMPANY_ADMIN.DASHBOARD}`);
			else if (data?.user_type === UserType.BRANCH_ADMIN)
				navigate(`/${ROUTING_TREE.IN_STORE_ADMIN.IN_STORE}/${ROUTING_TREE.IN_STORE_ADMIN.DASHBOARD}`);
			else navigate(ROUTING_TREE.HOME.HOME);
		}
	};

	// Handle data after returning self
	useEffect(() => {
		if (dataMe) {
			setRouting(dataMe);
		}

		if (dataBranchMe) {
			setRouting(dataBranchMe);
		}

		if (errorMe) {
			console.log(errorMe);
		}
		if (errorBranchMe) {
			console.log(errorBranchMe);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataMe, errorMe, dataBranchMe, errorBranchMe]);

	// Handle auth form data
	const setAuthFormFields = (field) => (event) => {
		if (field === "showPassword") {
			setAuthForm({
				...authForm,
				showPassword: !authForm.showPassword,
			});
		}

		setAuthForm({ ...authForm, [field]: event.target.value });
	};
	const setAuthBranchFormFields = (field) => (event) => {
		setAuthBranchForm({ ...authBranchForm, [field]: event.target.value });
	};

	// Login handler
	const login = (e) => {
		e.preventDefault();

		setCookie("is_keep_sign_in", keepSignIn, {
			expires: cookiesExpiresDay,
			path: "/",
		});

		if (authForm.email && authForm.password) {
			const formData = new FormData();
			formData.append("username", authForm.email);
			formData.append("password", authForm.password);
			jwtLogin(formData);
		}

		if (authBranchForm.branch_id && authBranchForm.password) {
			loginWithBranchId(authBranchForm);
		}
	};

	// Logout handler
	const logout = () => {
		jwtLogout();
	};

	// Account verification handler
	const requestAccountVerification = () => {
		if (authForm.email) {
			requestVerification({ email: authForm.email });
		}
	};

	return {
		authForm,
		setAuthForm,
		authBranchForm,
		setAuthBranchForm,
		notVerified,
		setNotVerified,
		badCredentials,
		setBadCredentials,
		unauthorized,
		setUnauthorized,
		login,
		loginData,
		loginError,
		loginIsLoading,
		logout,
		logoutData,
		logoutError,
		logoutIsLoading,
		loginBranchData,
		loginBranchError,
		loginBranchIsLoading,
		requestAccountVerification,
		requestVerificationData,
		requestVerificationError,
		user,
		me,
		access_token,
		token_type,
		refetchMe,
		setAuthFormFields,
		setAuthBranchFormFields,
		navigate,
		keepSignIn,
		setKeepSignIn,
		handleCheckKeepSignIn,
		loginWithBranchId,
		removeCookiesLogout,
		handleLogout,
	};
};

export default useAuth;
